import { useLayoutEffect, useState } from 'react';

interface WindowSize {
    width: number;
    height: number;
}

/**
 * Returns the window size.
 * @returns { width: number; height: number }
 */
const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState<WindowSize>({ width: window.innerWidth, height: window.innerHeight });

    /**
     * Handles the window size.
     * Sets the window size to the state.
     */
    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    /**
     * Adds an event listener to the window.
     * Removes the event listener when the component unmounts.
     */
    useLayoutEffect(() => {
        handleSize();
        window.addEventListener('resize', handleSize);
        return () => window.removeEventListener('resize', handleSize);
    }, []);

    return windowSize;
};

export { useWindowSize };
