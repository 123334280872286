import { useEffect, useRef, useCallback } from 'react';
import { whichOS } from 'helpers/whichOS';

export enum KeyboardShortcutScopes {
    Global = 'Global',
    TDTimeline = 'TDTimeline',
    TDCanvas = 'TDCanvas',
    TDEditor = 'TDEditor'
}

/**
 * Scope for keyboard shortcuts.
 */
export class KeyboardShortcutScope {
    /**
     * The current scope for keyboard shortcuts.
     */
    private static scope: KeyboardShortcutScopes = KeyboardShortcutScopes.Global;

    /**
     * Get the current scope for keyboard shortcuts.
     * @returns The current scope for keyboard shortcuts.
     */
    public static getScope(): KeyboardShortcutScopes {
        return this.scope;
    }

    /**
     * Set the scope for keyboard shortcuts.
     * @param newScope - The new scope for keyboard shortcuts.
     */
    public static setScope(newScope: KeyboardShortcutScopes): void {
        this.scope = newScope;
    }
}

interface Options {
    /**
     * Whether the keyboard shortcuts are enabled.
     * Default is true.
     */
    enabled?: boolean;
    /**
     * The scope for the keyboard shortcuts.
     * If the scope is set, the keyboard shortcuts will only be triggered if the current scope matches the target scope.
     * If the scope is not set, the keyboard shortcuts will be triggered regardless of the current scope.
     */
    scopes?: KeyboardShortcutScopes[];
    /**
     * Whether to allow the shortcut to repeat.
     * Default is false.
     */
    repeat?: boolean;
    /**
     * The combination key to use for the shortcut.
     * Change this when you want to use '+' for the shortcut.
     * Default is '+'.
     */
    combinationKey?: string;
    /**
     * Whether to enable the keyboard shortcuts on form input.
     * Default is false.
     */
    enableOnFormInputs?: boolean;
    /**
     * Whether to enable the keyUp event.
     * Default is false.
     */
    keyUp?: boolean;
    /**
     * Whether to enable the keyDown event.
     * Default is true.
     */
    keyDown?: boolean;
    /**
     * Whether to prevent the default behavior of the keyboard event.
     * Default is true.
     */
    preventDefault?: boolean;
}

/**
 * Handler for keyboard shortcuts.
 * @param event - The keyboard event.
 * @param shortcut - The keyboard shortcut that was pressed.
 */
type Handler = (event: KeyboardEvent, shortcut: string) => void;

/**
 * Hook to handle keyboard shortcuts.
 * @param shortcuts - The keyboard shortcuts to listen for. e.g. ['ctrl+s', 'cmd+s']
 * @param handler - The handler to call when the shortcut is pressed, the scope matches, and the conditions are met.
 * @param options - Options for the hook.
 * @param dependencies - Dependencies for the handler. If the handler uses any variables from the component, they should be added here.
 */
export const useKeyboardShortcuts = (shortcuts: string[], handler: Handler, options?: Options, dependencies: unknown[] = []): void => {
    const {
        enabled = true,
        scopes: targetScopes,
        repeat = false,
        combinationKey = '+',
        enableOnFormInputs = false,
        keyUp = false,
        keyDown = true,
        preventDefault = true
    } = options ?? {};

    const savedHandler = useRef<Handler>();

    /**
     * Memoize the handler to prevent unnecessary re-renders.
     */
    const memoizedHandler = useCallback(handler, dependencies);

    /**
     * Update the ref each time the handler changes.
     */
    useEffect(() => {
        savedHandler.current = memoizedHandler;
    }, [memoizedHandler]);

    /**
     * Add event listener when the component mounts.
     */
    useEffect(() => {
        const handleKeydown = (event: KeyboardEvent) => {
            if (!savedHandler.current) return;

            /**
             * Prevent the keyboard shortcuts from triggering when it is not in the correct scope.
             */
            const currentScope = KeyboardShortcutScope.getScope();
            const correctScope = !targetScopes || targetScopes.includes(KeyboardShortcutScopes.Global) || targetScopes.includes(currentScope);
            if (!correctScope) return;

            // Prevent the keyboard shortcuts from triggering when holding down a key.
            if (!repeat && event.repeat) return;

            /**
             * Prevent the keyboard shortcuts from triggering when typing in form inputs.
             */
            if (!enableOnFormInputs && event.target && ['input', 'textarea'].includes((event.target as HTMLElement).tagName.toLowerCase())) {
                return;
            }

            /**
             * Build the keycombo based on the OS.
             */
            const isMac = whichOS() === 'MacOS';
            let keyCombo = '';

            if (isMac) {
                if (event.metaKey) keyCombo += `cmd${combinationKey}`;
            } else {
                if (event.ctrlKey) keyCombo += `ctrl${combinationKey}`;
            }

            if (event.shiftKey) keyCombo += `shift${combinationKey}`;
            if (event.altKey) keyCombo += `alt${combinationKey}`;

            // Check if the key is a string and add it to the keyCombo.
            if (event.key && typeof event.key === 'string') {
                keyCombo += event.key.toLowerCase();
            }

            if (!shortcuts.includes(keyCombo)) return;

            preventDefault && event.preventDefault();
            savedHandler.current(event, keyCombo);
        };

        if (enabled) {
            keyUp && window.addEventListener('keyup', handleKeydown);
            keyDown && window.addEventListener('keydown', handleKeydown);
        }

        return () => {
            keyUp && window.removeEventListener('keyup', handleKeydown);
            keyDown && window.removeEventListener('keydown', handleKeydown);
        };
    }, [enabled, targetScopes, shortcuts, repeat, combinationKey, enableOnFormInputs, keyUp, keyDown, preventDefault]);
};
